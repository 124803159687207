export default [
    {
        path: '/utilisateurs/list',
        name: 'utilisateurs/list',
        component: () => import('@/views/lists/Utilisateurs.vue'),
        meta: {
            resource: 'params',
            action: 'read',
            pageTitle: 'Liste des utilisateurs',
            breadcrumb: [
                {
                    text: 'Paramètres',
                    active: false
                },
                {
                    text: 'Liste Utilisateurs',
                    active: true,
                },
            ],
        },
    },

    {
        path: '/utilisateurs/:utilisateurId/edit',
        name: 'utilisateurs/edit',
        component: () => import('@/views/forms/Utilisateur.vue'),
        meta: {
            resource: 'params',
            action: 'write',
            pageTitle: 'Modifier Utilisateur',
            breadcrumb: [
                {
                    text: 'Paramètres',
                },
                {
                    text: 'Liste Utilisateurs',
                    to: "/utilisateurs/list"
                },
                {
                    text: 'Modifier Utilisateur',
                    active: true,
                },
            ],
        },
    },

    {
        path: '/utilisateurs/add',
        name: 'utilisateurs/add',
        component: () => import('@/views/forms/Utilisateur.vue'),
        meta: {
            resource: 'params',
            action: 'write',
            pageTitle: 'Ajouter Utilisateur',
            breadcrumb: [
                {
                    text: 'Paramètres',
                    active: false
                },
                {
                    text: 'Liste Utilisateurs',
                    to: "/utilisateurs/list"
                },
                {
                    text: 'Ajouter Utilisateur',
                    active: true,
                },
            ],
        },
    },
]


