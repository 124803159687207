import authApi from '@/api/auth';
import { basicAbility, initialAbility } from '@/libs/acl/config'
import Vue from 'vue';

const state =  {
    clients: [],
    agences: [],
    decStatuts: [],
    affaireStatuts: [],
    pointGestionStatuts: [],
    affaireTypes: [],
    tauxTvas: [],
    users: [],
    comptabilites: [],
    comptabiliteTypes: [],

    user: null,
    token: '',
    refreshToken: '',
    redirectPath: null,

    listFiltersValues: {},
};

const _setters = Object.keys(state).reduce(
    (settersObject, key) => ({...settersObject, [`set${key[0].toUpperCase() + key.slice(1)}`]: (state, value) =>(state[key] = value)})
, {});

const _getters = Object.keys(state).reduce(
    (settersObject, key) => ({...settersObject, [`get${key[0].toUpperCase() + key.slice(1)}`]: (state) => state[key]})
, {});

const getters = {
    isUserLoggedIn: state => state.token.length > 0,
    getToken: state => state.token,
    getRedirectPath: state => state.redirectPath,
    showDecsOnlyUser: state => state.user.roles.every(role => !["ROLE_DIRECTION", "ROLE_COMPTABILITE"].includes(role)),
    isGranted: state => role => state.user.roles.includes(role),
    getUserAbility: (state, getters) => {
        let rights = [...basicAbility];
        if(state.user != null) {
            if(getters.isGranted("ROLE_ADMINISTRATIF") || getters.isGranted("ROLE_SUPER_ADMIN"))
                rights.push({"action": "manage", "subject": "params"});

            if(getters.isGranted("ROLE_DIRECTION") || getters.isGranted("ROLE_COMPTABILITE")) {
                rights.push({"action": "manage", "subject": "gestion_direction"});
                rights.push({"action": "manage", "subject": "bord_direction"});
            }
        }

        return rights;
    },

    getFiltersForContext: (state) => context => state.listFiltersValues.hasOwnProperty(context) ? state.listFiltersValues[context] : null
}

const mutations = {
    removeClientFromList: (state, clientId) => {
        let index = state.clients.findIndex(c => c.id == clientId);
        if(index != -1)
            state.clients.splice(index, 1)
    },

    removeAgenceFromList: (state, agenceId) => {
        let index = state.agences.findIndex(a => a.id == agenceId);
        if(index != -1)
            state.agences.splice(index, 1)
    },

    removeAffaireTypeFromList: (state, affaireTypeId) => {
        let index = state.affaireTypes.findIndex(a => a.id == affaireTypeId);
        if(index != -1)
            state.affaireTypes.splice(index, 1)
    },

    setListFilters: (state, {context, filters}) => {
        Vue.set(state.listFiltersValues, context, filters)
        console.log("set filters", context, state.listFiltersValues)
    },

    resetAllFilters: (state) =>  {
        Object.keys(state.listFiltersValues).forEach(key => {
            state.listFiltersValues[key] = null;
        })
    },

    resetFilters: (state, context) => {
        if(state.listFiltersValues.hasOwnProperty(context))
            state.listFiltersValues[context] = null
    }
}

const actions = {
    login: async ({commit, getters}, {username, password}) => {
        try {
            let logInfos = await authApi.login(username, password);
            commit('setToken',logInfos.token);
            commit('setRefreshToken',logInfos.refresh_token);
            commit('setUser', JSON.parse(logInfos.user));
            Vue.prototype.$ability.update(getters.getUserAbility);
        }
        catch(e)
        {
            throw e;
        }
        
    },
    logout: ({commit}) => {
        commit('setToken','');
        commit('setUser',null);
        commit('setRedirectPath',null);
        Vue.prototype.$ability.update(initialAbility);
    },
}

export default {
    namespaced: true,
    state,
    getters: {...getters, ..._getters},
    mutations: {..._setters, ...mutations},
    actions
}