import {callUrl} from "./api";
import jwtDefaultConfig from '@/auth/jwt/service/jwtDefaultConfig'

export default {
    async login(username, password) {
        try {
            let url = jwtDefaultConfig.loginEndpoint.substring(1)
            let res = await callUrl("POST", url, {username, password}, [200]);
            return res;
        } catch(e) {
            throw e;
        }
    },
}