export default [
    {
        path: '/clients/list',
        name: 'clients/list',
        component: () => import('@/views/lists/Clients.vue'),
        meta: {
            resource: 'params',
            action: 'read',
            pageTitle: 'Liste des clients',
            breadcrumb: [
                {
                    text: 'Paramètres',
                    active: false
                },
                {
                    text: 'Liste Clients',
                    active: true,
                },
            ],
        },
    },

    {
        path: '/clients/:clientId/edit',
        name: 'clients/edit',
        component: () => import('@/views/forms/Client.vue'),
        meta: {
            resource: 'params',
            action: 'write',
            pageTitle: 'Modifier Client',
            breadcrumb: [
                {
                    text: 'Paramètres',
                },
                {
                    text: 'Liste Clients',
                    to: "/clients/list"
                },
                {
                    text: 'Modifier Client',
                    active: true,
                },
            ],
        },
    },

    {
        path: '/clients/add',
        name: 'clients/add',
        component: () => import('@/views/forms/Client.vue'),
        meta: {
            resource: 'params',
            action: 'write',
            pageTitle: 'Ajouter Client',
            breadcrumb: [
                {
                    text: 'Paramètres',
                    active: false
                },
                {
                    text: 'Liste Clients',
                    to: "/clients/list"
                },
                {
                    text: 'Ajouter Client',
                    active: true,
                },
            ],
        },
    },
]


