import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist';


// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import data from "./data"

Vue.use(Vuex)

//persistance du store dans le navigateur
//voir s'il faut stocker certaines données en cookie, ou localForage ...
const vuexLocal = new VuexPersistence({storage: window.localStorage});

export default new Vuex.Store({
  modules: {
    app,
    data,
    appConfig,
    verticalMenu,
  },
  strict: process.env.DEV,
  plugins: [vuexLocal.plugin]
})
