export default [
    {
        path: '/tableau_bord/list',
        name: 'tableau_bord/list',
        component: () => import('@/views/lists/TableauxBord.vue'),
        meta: {
            pageTitle: 'Tableaux de Bord',
            breadcrumb: [
                {
                    text: 'Gestion',
                    active: false
                },
                {
                    text: 'Liste Tableaux de Bord',
                    active: true,
                },
            ],
            resource: 'bord_direction',
            action: 'read'
        },
    },

    {
        path: '/tableau_bord/:tableauBordId/edit',
        name: 'tableau_bord/edit',
        component: () => import('@/views/forms/TableauBord.vue'),
        meta: {
            resource: 'bord_direction',
            action: 'write',
            pageTitle: 'Consulter Tableau de Bord',
            breadcrumb: [
                {
                    text: 'Bord',
                },
                {
                    text: 'Liste Tableaux Bord',
                    to: "/tableau_bord/list"
                },
                {
                    text: 'Consulter Tableau de Bord',
                    active: true,
                },
            ],
        },
    },

    {
        path: '/tableau_bord/add',
        name: 'tableau_bord/add',
        component: () => import('@/views/forms/TableauBord.vue'),
        meta: {
            resource: 'bord_direction',
            action: 'write',
            pageTitle: 'Ajouter Tableau de Bord',
            breadcrumb: [
                {
                    text: 'Bord',
                    active: false
                },
                {
                    text: 'Liste Tableaux Bord',
                    to: "/tableau_bord/list"
                },
                {
                    text: 'Ajouter Tableau de Bord',
                    active: true,
                },
            ],
        },
    },
]


