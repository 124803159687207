import jwtDefaultConfig from './jwtDefaultConfig'
import store from '@/store'
import router from '@/router'
import {APP_URL} from "@/api/api"

export default class JwtService {
  // Will be used by this service for making API calls
  axiosIns = null

  // jwtConfig <= Will be used by this service
  jwtConfig = { ...jwtDefaultConfig }

  // For Refreshing Token
  isAlreadyFetchingAccessToken = false

  // For Refreshing Token
  subscribers = []

 constructor(axiosIns, jwtOverrideConfig) {
    this.axiosIns = axiosIns
    this.jwtConfig = { ...this.jwtConfig, ...jwtOverrideConfig }

    // Request Interceptor
    this.axiosIns.interceptors.request.use(
      config => {
        if (!config.hasOwnProperty('retryRequest')) {
          config.method = config.method.toUpperCase();
          config.headers = config.method == "PATCH" ? {'content-type': "application/merge-patch+json"} : config.headers;
          config.url = APP_URL + "/" + config.url;
        }
        // Get token from localStorage
        const accessToken = this.getToken()

        // If token is present add it to request's Authorization Header
        if (accessToken && !config.url.endsWith(this.jwtConfig.loginEndpoint)) {
          // eslint-disable-next-line no-param-reassign
          config.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
        }
        return config
      },
      error => Promise.reject(error),
    )

    // Add request/response interceptor
    this.axiosIns.interceptors.response.use(
      response => response,
      async (error) => {
        // const { config, response: { status } } = error
        const { config, response } = error
        const originalRequest = config
        console.log('intercept error',originalRequest);
        if (response && response.data.hasOwnProperty('message'))
          error = {error, message :response.data.message};

        //le refresh n'a pas fonctionné on sort
        if (originalRequest.url.endsWith(this.jwtConfig.refreshEndpoint) && response && response.status === 401) {
          this.isAlreadyFetchingAccessToken = false
          this.subscribers = [];
          console.log('refresh token error')
          await this.logout();
          return Promise.reject(error);
        }

        //on vérifie que c'est pas l'appel pour obtenir le token qui renvoie 401 pour un mauvais login/mot de passe
        if (!originalRequest.url.endsWith(this.jwtConfig.loginEndpoint) && response && response.status === 401) {
          if (!this.isAlreadyFetchingAccessToken) {
            console.log('refresh Token');
            this.isAlreadyFetchingAccessToken = true
            this.refreshToken().then(r => {
              console.log('refresh token ok');
              this.isAlreadyFetchingAccessToken = false

              // Update accessToken in localStorage
              this.setToken(r.data.token)
              this.setRefreshToken(r.data.refresh_token)

              this.onAccessTokenFetched(r.data.token)
            })
          }
          const retryOriginalRequest = new Promise(resolve => {
            this.addSubscriber(async (accessToken) => {
              // Make sure to assign accessToken according to your response.
              // Check: https://pixinvent.ticksy.com/ticket/2413870
              // Change Authorization header
              originalRequest.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`;
              originalRequest.retryRequest = true;
              try {
                console.log('retry après refresh');
                const retryResult = await this.axiosIns(originalRequest);
                resolve(retryResult);
              }
              catch (errorRetry){
                console.log('Erreur lors du retry après refresh');
                await this.logout();
                Promise.reject(errorRetry);
              }
              
            })
          })
          return retryOriginalRequest

        }
        
        return Promise.reject(error)
      },
    )
  }

  
  setToken(newToken) {
    store.commit('data/setToken',newToken);
  }

  setRefreshToken(newToken) {
    store.commit('data/setRefreshToken',newToken);
  }

  getToken() {
    return store.getters['data/getToken'];
  }

  async logout() {
    await store.dispatch('data/logout');
    router.push({name: 'login'});
  }


  getRefreshToken() {
    return store.getters['data/getRefreshToken'];
  }

  refreshToken() {
    return this.axiosIns.post(this.jwtConfig.refreshEndpoint, {
      refresh_token: this.getRefreshToken(),
    })
  }

  onAccessTokenFetched(accessToken) {
    this.subscribers = this.subscribers.filter(callback => callback(accessToken))
  }

  addSubscriber(callback) {
    this.subscribers.push(callback)
  }

}
