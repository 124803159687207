import Vue from "vue"
// !dommage, ne semble pas fonctionner. travail pour l'intercepteur ?
/* Vue.prototype.$http.defaults.headers.patch['Content-Type'] = "application/merge-patch+json" */

const callUrl = async function(method, url, params = {}, acceptedStatus = [200, 201, 204], headers = {}) {
    try {
        if(method == "GET" && params != null && typeof params === "object" && Object.entries(params).length > 0) {
            let parameters = Object.entries(params).map(([key, value]) => `${key}=${value}`)
            parameters = "?" + parameters.join("&");
            url += parameters;
            params = null;
        }

        let {data, status} = await Vue.prototype.$http({
            method,
            url,
            data: params,
            headers: headers,
        });

        if(!acceptedStatus.includes(status))
            throw status;

        return data;
    } catch(e) {
        console.log("une erreur api s'est produite", url, method, e)
        throw e;
    }
}

const downloadFile = async function(url, filename, params = {}) {
    try {
        let {data, status} = await Vue.prototype.$http({
            method: "POST",
            url,
            responseType: 'blob',
            data: params,
        });

        const dlurl = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement('a');
        link.href = dlurl;
        link.setAttribute('download', filename); //or any other extension
        document.body.appendChild(link);
        link.click();
        return 1;

    } catch(e) {
        console.log("une erreur api s'est produite", url, e)
        return -1;
    }
}

const APP_URL = ''


export {callUrl, APP_URL, downloadFile}