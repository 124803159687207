export default [
    {
        path: '/tableau_gestions/list',
        name: 'tableau_gestions/list',
        component: () => import('@/views/lists/TableauxGestion.vue'),
        meta: {
            pageTitle: 'Tableaux de Gestion',
            breadcrumb: [
                {
                    text: 'Gestion',
                    active: false
                },
                {
                    text: 'Liste Tableaux de Gestion',
                    active: true,
                },
            ],
            resource: 'gestion_direction',
            action: 'read'
        },
    },

    {
        path: '/tableau_gestions/:tableauGestionId/edit',
        name: 'tableau_gestions/edit',
        component: () => import('@/views/forms/TableauGestion.vue'),
        meta: {
            resource: 'gestion_direction',
            action: 'write',
            pageTitle: 'Consulter Tableau de Gestion',
            breadcrumb: [
                {
                    text: 'Gestion',
                },
                {
                    text: 'Liste Tableaux Gestion',
                    to: "/tableau_gestions/list"
                },
                {
                    text: 'Consulter Tableau de Gestion',
                    active: true,
                },
            ],
        },
    },

    {
        path: '/tableau_gestions/add',
        name: 'tableau_gestions/add',
        component: () => import('@/views/forms/TableauGestion.vue'),
        meta: {
            resource: 'gestion_direction',
            action: 'write',
            pageTitle: 'Ajouter Tableau de Gestion',
            breadcrumb: [
                {
                    text: 'Gestion',
                    active: false
                },
                {
                    text: 'Liste Tableaux Gestion',
                    to: "/tableau_gestions/list"
                },
                {
                    text: 'Ajouter Tableau de Gestion',
                    active: true,
                },
            ],
        },
    },
]


