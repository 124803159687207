export default [
    {
        path: '/affaires/list',
        name: 'affaires/list',
        component: () => import('@/views/lists/Affaires.vue'),
        meta: {
            resource: 'gestion',
            action: 'read',
            pageTitle: 'Liste des Affaires',
            breadcrumb: [
                {
                    text: 'Gestion',
                    active: false
                },
                {
                    text: 'Liste Affaires',
                    active: true,
                },
            ],
        },
    },

    {
        path: '/affaires/:affaireId/edit',
        name: 'affaires/edit',
        component: () => import('@/views/forms/Affaire.vue'),
        meta: {
            resource: 'gestion',
            action: 'write',
            pageTitle: 'Modifier Affaire',
            breadcrumb: [
                {
                    text: 'Gestion',
                },
                {
                    text: 'Liste Affaires',
                    to: "/affaires/list"
                },
                {
                    text: 'Modifier Affaire',
                    active: true,
                },
            ],
        },
    },

    {
        path: '/affaires/add',
        name: 'affaires/add',
        component: () => import('@/views/forms/Affaire.vue'),
        meta: {
            resource: 'gestion',
            action: 'write',
            pageTitle: 'Ajouter Affaire',
            breadcrumb: [
                {
                    text: 'Gestion',
                    active: false
                },
                {
                    text: 'Liste Affaires',
                    to: "/affaires/list"
                },
                {
                    text: 'Ajouter Affaire',
                    active: true,
                },
            ],
        },
    },
]


