
export const initialAbility = [{
  action: 'read',
  subject: 'Auth',
}]


export const basicAbility = [
  {
    action: 'manage',
    subject: 'home',
  },
  {
    action: 'manage',
    subject: 'gestion',
  },
  ...initialAbility
]

export const _ = undefined
