export default {
  // Endpoints
  loginEndpoint: '/authentication_token',
  refreshEndpoint: 'api/token/refresh',

  // This will be prefixed in authorization header with token
  // e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',

}
