export default [
    {
        path: '/affaire_types/list',
        name: 'affaire_types/list',
        component: () => import('@/views/lists/AffaireTypes.vue'),
        meta: {
            resource: 'params',
            action: 'read',
            pageTitle: 'Liste des Types Affaire',
            breadcrumb: [
                {
                    text: 'Paramètres',
                    active: false
                },
                {
                    text: 'Liste Types Affaire',
                    active: true,
                },
            ],
        },
    },

    {
        path: '/affaire_types/:affaireTypeId/edit',
        name: 'affaire_types/edit',
        component: () => import('@/views/forms/AffaireType.vue'),
        meta: {
            resource: 'params',
            action: 'write',
            pageTitle: 'Modifier Type Affaire',
            breadcrumb: [
                {
                    text: 'Paramètres',
                },
                {
                    text: 'Liste Types Affaire',
                    to: "/affaire_types/list"
                },
                {
                    text: 'Modifier Type Affaire',
                    active: true,
                },
            ],
        },
    },

    {
        path: '/affaire_types/add',
        name: 'affaire_types/add',
        component: () => import('@/views/forms/AffaireType.vue'),
        meta: {
            resource: 'params',
            action: 'write',
            pageTitle: 'Ajouter Type Affaire',
            breadcrumb: [
                {
                    text: 'Paramètres',
                    active: false
                },
                {
                    text: 'Liste Types Affaire',
                    to: "/affaire_types/list"
                },
                {
                    text: 'Ajouter Type Affaire',
                    active: true,
                },
            ],
        },
    },
]


