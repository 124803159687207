export default [
    {
        path: '/pointGestions/:pointGestionId/edit',
        name: 'pointGestions/edit',
        component: () => import('@/views/forms/PointGestion.vue'),
        meta: {
            resource: 'gestion',
            action: 'write',
            pageTitle: 'Modifier Point Gestion',
            breadcrumb: [
                {
                    text: 'Gestion',
                },
                {
                    text: 'Liste Affaires',
                    to: "/affaires/list"
                },
                {
                    text: 'Modifier Affaire',
                },
                {
                    text: "Point Gestion",
                    active: true,
                }
            ],
        },
    },

    {
        path: '/pointGestions/:affaireId/add',
        name: 'pointGestions/add',
        component: () => import('@/views/forms/PointGestion.vue'),
        meta: {
            resource: 'gestion',
            action: 'write',
            pageTitle: 'Modifier Point Gestion',
            breadcrumb: [
                {
                    text: 'Gestion',
                },
                {
                    text: 'Liste Affaires',
                    to: "/affaires/list"
                },
                {
                    text: 'Modifier Affaire',
                    to: '/affaires/:affaireId/edit',
                },
                {
                    text: "Ajouter Point Gestion",
                    active: true,
                }
            ],
        },
    },
]


