export default [
    {
        path: '/decs/list',
        name: 'decs/list',
        component: () => import('@/views/lists/Decs.vue'),
        meta: {
            resource: 'gestion',
            action: 'read',
            pageTitle: 'Liste des DECs',
            breadcrumb: [
                {
                    text: 'Gestion',
                    active: false
                },
                {
                    text: 'Liste DECs',
                    active: true,
                },
            ],
        },
    },

    {
        path: '/decs/:decId/edit',
        name: 'decs/edit',
        component: () => import('@/views/forms/Dec.vue'),
        meta: {
            resource: 'gestion',
            action: 'write',
            pageTitle: 'Modifier DEC',
            breadcrumb: [
                {
                    text: 'Gestion',
                },
                {
                    text: 'Liste DECs',
                    to: "/decs/list"
                },
                {
                    text: 'Modifier DEC',
                    active: true,
                },
            ],
        },
    },

    {
        path: '/decs/add',
        name: 'decs/add',
        component: () => import('@/views/forms/Dec.vue'),
        meta: {
            resource: 'gestion',
            action: 'write',
            pageTitle: 'Ajouter DEC',
            breadcrumb: [
                {
                    text: 'Gestion',
                    active: false
                },
                {
                    text: 'Liste DECs',
                    to: "/decs/list"
                },
                {
                    text: 'Ajouter DEC',
                    active: true,
                },
            ],
        },
    },
]


