import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

import { canNavigate } from '@/libs/acl/routeProtection'

var routes = [];

const requireModules = require.context('./', false, /.(js)$/)
requireModules.keys().forEach(fileName => {
    let moduleFileName = fileName.split('/').pop().replace(/\.\w+$/, '');
	if(moduleFileName != "index") {
		const moduleRoutes = require('./' + moduleFileName).default;
		routes.push(...moduleRoutes); 
	}
})

import store from '@/store'


const router = new VueRouter({
	mode: 'hash',
	base: process.env.BASE_URL,

	scrollBehavior() {
		return { x: 0, y: 0 }
	},

	routes: [
		// on inclut toutes les routes définies dans les fichiers séparés
		...routes,

		{
			path: '/',
			name: 'home',
			component: () => import('@/views/Home.vue'),
			meta: {
				pageTitle: 'Accueil',
				breadcrumb: [
					{
						text: 'Accueil',
						active: true,
					},
				],
				resource: 'home',
				action: 'read'
			},
		},

		{
			path: '/login',
			name: 'login',
			component: () => import('@/views/Login.vue'),
			meta: {
				layout: 'full',
				authRequired: false,
				resource: 'Auth',
				action: 'read'
			},
		},

		{
			path: '/error-404',
			name: 'error-404',
			component: () => import('@/views/error/Error404.vue'),
			meta: {
				layout: 'full',
				authRequired: false,
				resource: 'Auth',
				action: 'read'
			},
		},

		{
			path: '*',
			redirect: 'error-404',
		},
	],
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
	// Remove initial loading
	const appLoading = document.getElementById('loading-bg')
	if (appLoading) {
		appLoading.style.display = 'none'
	}
})

//protection des pages
router.beforeEach((to, from, next) => {
	const userLoggedIn =  store.getters['data/isUserLoggedIn'];
	if (to.matched.some(record => (!record.meta.hasOwnProperty('authRequired') || record.meta.authRequired)) && !userLoggedIn) {
		store.commit('data/setRedirectPath',to);
		next({ name: 'login' });
	}
	else {
		if (!canNavigate(to)) {
			if (userLoggedIn)
				next({ name: 'home'});
			else
				next({name: 'login'});
		}
		else
			next();
	}
});

export default router
