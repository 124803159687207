export default [
    {
        path: '/agences/list',
        name: 'agences/list',
        component: () => import('@/views/lists/Agences.vue'),
        meta: {
            resource: 'params',
            action: 'read',
            pageTitle: 'Liste des agences',
            breadcrumb: [
                {
                    text: 'Paramètres',
                    active: false
                },
                {
                    text: 'Liste Agences',
                    active: true,
                },
            ],
        },
    },

    {
        path: '/agences/:agenceId/edit',
        name: 'agences/edit',
        component: () => import('@/views/forms/Agence.vue'),
        meta: {
            resource: 'params',
            action: 'write',
            pageTitle: 'Modifier Agence',
            breadcrumb: [
                {
                    text: 'Paramètres',
                },
                {
                    text: 'Liste Agences',
                    to: "/agences/list"
                },
                {
                    text: 'Modifier Agence',
                    active: true,
                },
            ],
        },
    },

    {
        path: '/agences/add',
        name: 'agences/add',
        component: () => import('@/views/forms/Agence.vue'),
        meta: {
            resource: 'params',
            action: 'write',
            pageTitle: 'Ajouter Agence',
            breadcrumb: [
                {
                    text: 'Paramètres',
                    active: false
                },
                {
                    text: 'Liste Agences',
                    to: "/agences/list"
                },
                {
                    text: 'Ajouter Agence',
                    active: true,
                },
            ],
        },
    },
]


